import { combineReducers } from "redux";

import session from "./session";
import navigation from "./navigation";
import villages from "./villages";

export default combineReducers({
    session,
    navigation,
    villages,
});
