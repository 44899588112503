import { s_accessToken, s_email, s_gauthid, s_userName, s_gauthimageurl } from "../reducers/session";

export const setS_gAuthId = (payload) => ({
    type: s_gauthid,
    payload,
});
export const setS_gAuthImageUrl = (payload) => ({
    type: s_gauthimageurl,
    payload,
});
export const setS_AccessToken = (payload) => ({
    type: s_accessToken,
    payload,
});
export const setS_UserName = (payload) => ({
    type: s_userName,
    payload,
});
export const setS_Email = (payload) => ({
    type: s_email,
    payload,
});
