import React, { lazy } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import history from "./utils/history";
import { setS_Email, setS_gAuthId, setS_gAuthImageUrl } from "./creators/session";
import { connect } from "react-redux";

const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));

function App(props) {
    const [isSignedIn, setIsSignedIn] = React.useState(false);
    const [isFetching, setIsFetching] = React.useState(true);

    React.useEffect(() => {
        window.gapi.load("auth2", function () {
            let auth2 = window.gapi.auth2.init({
                client_id: "894504942881-62oa721jlumv9u8vtv9k0cqq4lrjk4i5.apps.googleusercontent.com",
            });

            auth2.currentUser.listen((user) => {
                if (user.getBasicProfile()) {
                    console.log("user", user.getId(), user.getBasicProfile().getEmail());

                    /** setting-user-env */
                    setIsSignedIn(true);
                    props.setS_gAuthId(user.getId());
                    props.setS_Email(user.getBasicProfile().getEmail());
                    props.setS_gAuthImageUrl(user.getBasicProfile().getImageUrl());

                    history.push("/app");
                    /** setting-user-env */
                } else {
                    history.push("/login");
                }

                setIsFetching(false);
            });
        });
    }, []);

    if (isFetching) {
        return <div className="w-full h-screen p-6 text-lg font-medium text-gray-600">Loading...</div>;
    }

    return (
        <>
            <Router history={history}>
                <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/app" component={Layout} />

                    <Redirect exact from="/" to={isSignedIn ? "/app" : "/login"} />
                </Switch>
            </Router>
        </>
    );
}

const mapDispatchToProps = {
    setS_Email,
    setS_gAuthId,
    setS_gAuthImageUrl,
};

export default connect(null, mapDispatchToProps)(App);
