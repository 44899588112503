import { PURGE } from "redux-persist/es/constants";

export const current_page = "current_page";

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case current_page:
            return {
                ...state,
                current_page: action.payload,
            };
        case PURGE:
        default:
            return initialState;
    }
};
