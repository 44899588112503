import { PURGE } from "redux-persist/es/constants";

export const villages_data = "villages_data";

const initialState = {
    villages: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case villages_data:
            return {
                ...state,
                villages: action.payload,
            };
        case PURGE:
        default:
            return initialState;
    }
};
