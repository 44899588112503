import { PURGE } from "redux-persist/es/constants";

export const s_gauthid = "s_gauthid";
export const s_gauthimageurl = "s_gauthimageurl";
export const s_userName = "s_username";
export const s_firstName = "s_firstname";
export const s_lastName = "s_lastname";
export const s_email = "s_email";
export const s_accessToken = "s_accessToken";
export const s_isSignedIn = "s_isSignedIn";

const intialState = {};

export default (state = intialState, action) => {
    switch (action.type) {
        case s_gauthid:
            return {
                ...state,
                s_gauthid: action.payload,
            };
        case s_gauthimageurl:
            return {
                ...state,
                s_gauthimageurl: action.payload,
            };
        case s_accessToken:
            return {
                ...state,
                s_accessToken: action.payload,
            };
        case s_email:
            return {
                ...state,
                s_email: action.payload,
            };
        case s_userName:
            return {
                ...state,
                s_userName: action.payload,
            };
        case s_firstName:
            return {
                ...state,
                s_firstName: action.payload,
            };
        case s_lastName:
            return {
                ...state,
                s_lastName: action.payload,
            };
        case PURGE:
        default:
            return intialState;
    }
};
